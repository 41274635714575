@import '../../variable';

.login_page {
    background-image: $wiw-gradient;
    height: 100vh;
    .ant-form-item-explain-error {
        color: $color-black;
    }
    .welcom_baner {
        border: 2px solid $header-border-color;
        background-color: $white;
        text-align: center;
        padding: 16px;
        margin: 24px;
        border-radius: 4px;
    }
    .login_form {
        max-width: 600px;
        margin: 0 auto;
        @media screen and (max-width: $medium) {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
    .login_input {
        border: 2px solid $header-border-color;
        border-radius: 4px;
    }
    .ant-input-lg {
        border-radius: 0px;
    }
    .login_buttom {
        width: 100%;
        border: 2px solid $header-border-color;
        border-radius: 4px;
        background-color: $color-black-button;
        color: $white;
    }
    .ant-btn-default:not(:disabled):active, .ant-btn-default:not(:disabled):hover {
        border-color: $header-border-color;
        color: $white;
    }
}
