@import '../../../variable';

.control-button {
    background-image: $wiw-gradient;
    /*position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;*/
    border-radius: $control-radius;
    padding: 4px;
    margin-bottom: $margin-default;

    button.ant-btn.ant-btn-icon-only {
        width: 30%;
        border: $border-button;
        color: $color-black;
    }
    button.ant-btn.ant-btn-icon-only:focus {
        outline: none;
        border: $border-button;
        box-shadow: none;
    }
    button.ant-btn.ant-btn-icon-only:disabled {
        background-color: $white;
        border-color: $color-black-hover;
    }
    button.ant-btn.ant-btn-icon-only:disabled .nav-icon {
        opacity: 0.3;
    }
}