@import '../../../variable';

.voting {
    margin-right: 0px;
    margin-left: 0px;
    .ant-slider-track {
        background-color: transparent;
    }
    .ant-slider-rail {
        background-image: $wiw-gradient-border;
        height: 32px;
        border-radius: $control-radius;
        border: $border-button;
    }
    .ant-slider-handle {
        width: 14.8%;
        height: 48px;
        border: $border-button;
        border-radius: 4px;
        transform: "none";
    }
    .ant-slider-handle::after, .ant-slider-handle::before, 
    .ant-slider-handle:active::after, .ant-slider-handle:active::before, 
    .ant-slider-handle:focus::after, .ant-slider-handle:focus::before {
        display: none;
    }
    .ant-slider-handle:active, .ant-slider-handle:focus {
        box-shadow: none !important;
    }
}
.voting.ant-slider:hover .ant-slider-track{
    background-color: transparent !important;
}
.voting.ant-slider-horizontal {
    margin-top: 0px;
    padding-block: 10px;
    height: 48px;
}
.handleStyles {
    background-color: $voting-red;
    transform: "none";
    left: "0%";
    border-radius: "5px 0px 0px 5px";
}