$header-color: #FDFCDD;
$color-black: #000000;
$color-button-disable: #b3b3b3;
$color-black-hover: rgba($color-black, .3);
$white: #fff;
$footer-border: #D9D9D9;
$header-border-color: $color-black;
$color-black-button: #383838;
$color-black-user-name: #262626;
$color-black-user-acc: rgba($color-black-user-name, .7);
$voting-red: #F92323;
$voting-red-light: #FC6D4E;
$voting-orange: #FCC490;
$voting-grey: #D9D9D9;
$voting-light-light-green: #97FCB6;
$voting-light-green: #1CD6A9;
$voting-green: #08AE6A;
$wiw-gradient: linear-gradient(90deg,
    $voting-red 0%, $voting-red 14.3%,
    $voting-red-light 14.3%, $voting-red-light 28.6%,
    $voting-orange 28.6%, $voting-orange 42.9%,
    $voting-grey 42.9%, $voting-grey 57.2%,
    $voting-light-light-green 57.2%, $voting-light-light-green 71.5%,
    $voting-light-green 71.5%, $voting-light-green 85.8%,
    $voting-green 85.8%, $voting-green 100%,
);
$wiw-gradient-border: linear-gradient(90deg,
    $voting-red 0%, $voting-red 14.2%,
    $color-black 14.2%, $color-black 14.4%,
    $voting-red-light 14.4%, $voting-red-light 28.5%,
    $color-black 28.5%, $color-black 28.7%,
    $voting-orange 28.7%, $voting-orange 42.8%,
    $color-black 42.8%, $color-black 43%,
    $voting-grey 43%, $voting-grey 57.1%,
    $color-black 57.1%, $color-black 57.3%,
    $voting-light-light-green 57.3%, $voting-light-light-green 71.4%,
    $color-black 71.4%, $color-black 71.6%,
    $voting-light-green 71.6%, $voting-light-green 85.7%,
    $color-black 85.7%, $color-black 85.9%,
    $voting-green 85.9%, $voting-green 100%,
);
$border-width: 2px;
$border-button: $border-width solid $color-black;
$button-radius: 4px;
$small: 480px;
$medium: 600px;
$large: 1024px;
$font-size-body: 16px;
$control-radius: 5px;
$user-vote-height: 48px;
$control-button-height: 32px;
$margin-default: 4px;
$margin-layot: 10px;
$input-padding: 12px;
$btn-radio-lng-height: 40px;

:export {
    header-color: $header-color;
    color-black: $color-black;
    white: $white;
    footer-border: $footer-border;
    header-border-color: $header-border-color;
    color-black-user-name: $color-black-user-name;
    color-black-user-acc: $color-black-user-acc;
    voting-red: $voting-red;
    voting-red-light: $voting-red-light;
    voting-orange: $voting-orange;
    voting-grey: $voting-grey;
    voting-light-light-green: $voting-light-light-green;
    voting-light-green: $voting-light-green;
    voting-green: $voting-green;
    border-width: $border-width;
    border-button: $border-button;
    button-radius: $button-radius;
    font-size-body: $font-size-body;
    control-radius: $control-radius;
    user-vote-height: $user-vote-height;
    control-button-height: $control-button-height;
    margin-default: $margin-default;
    margin-layot: $margin-layot;
    input-padding: $input-padding;
    btn-radio-lng-height: $btn-radio-lng-height;
}