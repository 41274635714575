@import '../../../variable';

.control-panel {
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: $white;
    z-index: 2;

    button.ant-btn.ant-btn-icon-only {
        color: $color-black;
    }
    button.ant-btn.ant-btn-icon-only:focus {
        outline: none;
        box-shadow: none;
    }
}