@import '../../variable';

.storyTitle {
    background-color: $white;
    border:  $border-button;
    border-radius: $button-radius;
    text-align: center;
    padding: 2px;
    line-height: normal;
    color: $color-black;
    position: relative;
}

.storyTitle.fill {
    background-color: $header-color;
}

.storyTitle-title {
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    font-family: "UbuntuM" !important;
    cursor: pointer;
}

.storyTitle .story_user_scope {
  position: absolute;
	top: -$border-width;
	right: -$border-width;
	width: 12.5%;
	margin-top: 0px;
}