@import '../../variable';

.post_user_icon {
    width: 48px;
    height: 48px;
}
.post_user_picture {
    border-radius: 50%;
    width: 48px;
    height: 48px;
}
.post_user_name {
    font-weight: bold;
    color: $color-black-user-name;
}
.post_user_acc {
    color: $color-black-user-acc;
}
.post_user_data {
    margin-top: 4px;
    font-size: 16px;
}