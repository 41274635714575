@import '../../variable';

.statistic_chart {
    height: 100%;
    padding-top: 45px;
    .bar_block {
        width: 14.37%;
        float: left;
        height: 100%;
        position: relative;
        margin: -2px -2px 0 0;
    }
    .bar {
        width: 100%;
        position: absolute;
        border-radius: 5px 5px 0px 0px;
        border: $border-button;
        bottom: 0px;
    }
    .bar_text {
        width: 100%;
        text-align: center;
        font-weight: bold;
        position: absolute;
        .bar_label {
            border: 2px solid $color-black;
            padding: 4px;
            border-radius: 5px;
        }
        .bar_label1 {
            border-color: $voting-red;
        }
        .bar_label2 {
            border-color: $voting-red-light;
        }
        .bar_label3 {
            border-color: $voting-orange;
        }
        .bar_label4 {
            border-color: $voting-grey;
        }
        .bar_label5 {
            border-color: $voting-light-light-green;
        }
        .bar_label6 {
            border-color: $voting-light-green;
        }
        .bar_label7 {
            border-color: $voting-green;
        }
    }
}

.bar1 {
    background-color: $voting-red;
    border-radius: 5px 5px 0px 5px;
}
.bar2 {
    background-color: $voting-red-light;
}
.bar3 {
    background-color: $voting-orange;
}
.bar4 {
    background-color: $voting-grey;
}
.bar5 {
    background-color: $voting-light-light-green;
}
.bar6 {
    background-color: $voting-light-green;
}
.bar7 {
    background-color: $voting-green;
    border-radius: 5px 5px 5px 0px;
}
